import React from "react";
import { Link } from "react-scroll";
import "./introduction.scss";


const Introduction = () => {

    return(
        <div className="introduction_container" id="Introduktion">
            <div className="introduction_content">
                <p className="introduction_intro-text">Hej mit navn er</p>
                <h2>Casper Nedergaard</h2>
                <h1>Webudvikler | frontend</h1>
                <p className="introduction_little-about">Jeg arbejder mest med frontend. Elsker at komme med nye kreative ideer til et nyt web design og går altid efter at siden skal være responsive og burgervenlig. Er altid klar til nye udfordringer.</p>
                <Link to="kontakt" spy={true} smooth={true} offset={-100} duration={500}><button>Kontakt mig</button></Link>
            </div>
        </div>
    );
}
export default Introduction;