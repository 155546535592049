import React from "react";
import "./aboutMe.scss";
import me from "../../assets/img/mig.jpg";
import cv from "../../assets/pdf/cv.pdf";


const AboutMe = () => {

    return(
        <div className="about-me_container" id="om-mig">
            <div className="about-me_font-text">
                <h2><span>02</span> Om mig</h2>
            </div>
            <div className="about-me_content">
                <div className="about-me_img-holder">
                    <img src={me} alt="" />
                </div>
                <div className="about-me_text-holder">
                    <h3>Hej jeg er <span>Casper Nedergaard</span></h3>
                    <ul>
                        <li><div className="about-me_info"><p>Navn:</p><p>Casper</p></div></li>
                        <li><div className="about-me_info"><p>Efternavn:</p><p>Nedergaard</p></div></li>
                        <li><div className="about-me_info"><p>Alder:</p><p>21 år</p></div></li>
                        <li><div className="about-me_info"><p>Land:</p><p>Danmark</p></div></li>
                        <li><div className="about-me_info"><p>Sprog:</p><p>Dansk og Engelsk</p></div></li>
                        <li><div className="about-me_info"><p>Adresse:</p><p>Silkeborg</p></div></li>
                        <li><div className="about-me_info"><p>Arbejde:</p><p>Ledig</p></div></li>
                    </ul>
                   <a href={cv} target="_blank"><button>Mit cv</button></a>
                </div>
            </div>
        </div>
    );
}
export default AboutMe;