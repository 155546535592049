import React, {useState} from "react";
import {Link} from "react-scroll";
import "./menu.scss";


const Menu = () => {

    
    const [showMenu, setShowMenu] = useState(false);


    const [fix, setFix] = useState(false);


   function setFixed() {
      if (window.scrollY >= 1) {
         setFix(true)
      } else {
         setFix(false)
      }
   }

   window.addEventListener("scroll", setFixed)

    return(
        <div className="menu_container">
        <nav className={fix ? 'Navbar fixedB' : 'Navbar'}>

            <Link to="Introduktion" spy={true} smooth={true} offset={-100} duration={500}>
                <div className="logo">
                   <div className="logo-center">
                      <p>C</p>
                    </div>
                </div>
            </Link>

            <div className={showMenu === true ? "navbar-container active" : "navbar-container"}>
            <ul>
                <li>
                    <Link onClick={() => setShowMenu(!showMenu)} to="Introduktion" spy={true} smooth={true} offset={-100} duration={500}><span>01.</span> Introduktion</Link>
                </li>
                <li>
                    <Link onClick={() => setShowMenu(!showMenu)} to="om-mig" spy={true} smooth={true} offset={-50} duration={500}><span>02.</span> Om mig</Link>
                </li>
                <li>
                    <Link onClick={() => setShowMenu(!showMenu)} to="projekter" spy={true} smooth={true} offset={-50} duration={500}><span>03.</span> Projekter</Link>
                </li>
                <li>
                    <Link onClick={() => setShowMenu(!showMenu)} to="kontakt" spy={true} smooth={true} offset={0} duration={500}><button>Kontakt</button></Link>
                </li>
            </ul>
            </div>

            <div className={showMenu === true ? "burger-button open" : "burger-button"} onClick={() => setShowMenu(!showMenu)}>
                <span className="bar bar1"></span>
                <span className="bar bar2"></span>
                <span className="bar bar3"></span>
            </div>

        </nav>
            <div className={showMenu === true ? "blur" : ""}></div>
        </div>
    );
}
export default Menu;