import React, { useEffect, useState } from "react";
import nyhed from "../../assets/img/nyhed.png";
import { motion } from "framer-motion";
import balloons from "../../assets/img/balloons.png";
import "./construction_modal.scss";
import { Link } from "react-scroll";


const Construction_modal = () => {

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";

    }, []);



    const close = () => {
        setOpenModal(true);
        document.body.style.overflow = "auto";
    }

    return (


        <div>
            <motion.div className="construction_outside-modal"
                animate={{
                    display: openModal ? "none" : ""
                }}
            />
            <motion.div className="construction_center-modal"
                initial={{
                    y: "-100vh"
                }}
                animate={{
                    y: openModal ? "100vh" : 0
                }}
                transition={{ type: "spring", stiffness: 50, duration: 2 }}
            >
                <div className="construction_modal">
                    <section className="construction_split">
                    <svg className="construction_x-mark-svg_mobile" onClick={close} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    <div>
                    <img src={balloons} alt="ballons" width="45%" />
                    <img src={balloons} alt="ballons" width="40%" />
                    </div>
                        <h2>Nyhed</h2>
                        <p className="construction_description">Jeg Præsenterer WAVEWONDER</p>
                        <p className="construction_description-2">Jeg er i gang med et spændende nyt projekt, hvor jeg udforsker og implementerer flere af de nyeste frameworks og værktøjer. En central del af dette projekt er brugen af React.js og Tailwind CSS, som giver mig mulighed for at udvikle en moderne og dynamisk brugergrænseflade. Til databasen har jeg valgt at anvende Supabase, en kraftfuld løsning med en række avancerede funktioner. I processen med at opbygge projektet integrerer jeg også andre værktøjer for at forbedre funktionaliteten og brugeroplevelsen. Et par af disse inkluderer React Router DOM, som gør det muligt for mig at oprette navigationsmuligheder i min applikation, samt Framer Motion, der tilføjer animationsmuligheder for at gøre brugerinteraktionen mere engagerende og indbydende. Samlet set kombinerer jeg disse forskellige værktøjer og teknologier for at skabe et sammenhængende og innovativt projektoplevelse.</p>

                    </section>
                    <section className="construction_x-mark">
                        <svg className="construction_x-mark-svg" onClick={close} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                        <div className="construction_x-mark-content">
                        <h2>Klik for at se projektet</h2>
                        <motion.div
                        initial={{
                            y: 0,
                        }}
                        animate={{
                            y: 20,
                        }}
                        transition={{
                            repeat: Infinity,
                            duration: 0.5,
                            repeatType: "mirror"
                        }}
                        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg></motion.div>
                        <div className="construction_button-holder">
                           <a href="https://wavewonder.casper-nedergaard-portfolio.dk/" target="_blank"><motion.div className="construction_button"
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.2 },
                              }}
                              whileTap={{ scale: 0.9 }}
                            
                            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg></motion.div></a>
                           <Link to="projekter" onClick={close}><motion.div className="construction_button"
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.2 },
                              }}
                              whileTap={{ scale: 0.9 }}

                            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 96c0 50.2-59.1 125.1-84.6 155c-3.8 4.4-9.4 6.1-14.5 5H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c53 0 96 43 96 96s-43 96-96 96H139.6c8.7-9.9 19.3-22.6 30-36.8c6.3-8.4 12.8-17.6 19-27.2H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-53 0-96-43-96-96s43-96 96-96h39.8c-21-31.5-39.8-67.7-39.8-96c0-53 43-96 96-96s96 43 96 96zM117.1 489.1c-3.8 4.3-7.2 8.1-10.1 11.3l-1.8 2-.2-.2c-6 4.6-14.6 4-20-1.8C59.8 473 0 402.5 0 352c0-53 43-96 96-96s96 43 96 96c0 30-21.1 67-43.5 97.9c-10.7 14.7-21.7 28-30.8 38.5l-.6 .7zM128 352a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM416 128a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg></motion.div></Link>
                        </div>
                        </div>
                        <img src={nyhed} alt="" />
                    </section>
                </div>
            </motion.div>
        </div>

    )
}

export default Construction_modal;



