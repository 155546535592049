import React from "react";
import "./home.scss";
import Menu from "../../layout/menu/Menu";
import SocialiconsBar from "../../layout/social_icons_bar/SocialiconsBar";
import Introduction from "../../layout/introduction/Introduction";
import AllProjects from "../../layout/all_projects/AllProjects";
import Contact from "../../layout/contact/Contact";
import AboutMe from "../../layout/about_me/AboutMe";
import Construction_modal from "../../layout/construction_modal/Construction_modal";


const Home = () => {

    return(
        <div className="home-container">
            <Construction_modal />
            <Menu />
            <SocialiconsBar />
            <Introduction />
            <AboutMe />
            <AllProjects />
            <Contact />
        </div>
    );
}
export default Home;