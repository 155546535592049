import React from "react";
import "./contact.scss";


const Contact = () => {

    return(
        <div className="contact_container" id="kontakt">
            <div className="contact_font-text">
                <h2><span>04</span> Kontakt mig</h2>
            </div>
            <div className="contact_content">
               <a href="tel:+4560166856">
                <div className="contact_box">
                    <div className="contact_round-box">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM304 64H80V384H304V64z"/></svg>
                    </div>
                    <div className="contact_text">
                        <h3>Telefon</h3>
                        <h4>[+45] 60 16 68 56</h4>
                    </div>
                </div>
                </a>
                <a href="mailto:casperwtrt.cn@gmail.com">
                <div className="contact_box">
                    <div className="contact_round-box">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                    </div>
                    <div className="contact_text">
                        <h3>Email</h3>
                        <h4>casperwtrt.cn@gmail.com</h4>
                    </div>
                </div>
                </a>
                <a href="https://www.google.com/maps/place/8600+Silkeborg/" target="_blank">
                <div className="contact_box">
                    <div className="contact_round-box">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>
                    </div>
                    <div className="contact_text">
                        <h3>Adresse</h3>
                        <h4>Silkeborg</h4>
                    </div>
                </div>
                </a>
            </div>
        </div>
    );
}
export default Contact;
